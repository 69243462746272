// @flow
import { useState, useEffect, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { AuthUtils } from 'auth/index';
import { Backend, get, put } from 'actions/backend';
import { useImageApi } from 'hooks/general/image';
import { EmptyUser } from 'types/user-props';
import MediaType from 'utils/variables/media-type';
import { TagGroup } from 'utils/variables/tag-group-vars';
import { transformTag } from 'utils/transform/transform-tag';
import { transformPhoto } from 'utils/transform';
import { THIRD_PARTIES } from 'utils/variables/third-party';
import { notificationService } from 'components/general/notification';
import ProfileEdit from 'components/general/account/settings/profile-edit';

const ProfileEditWrapper = (): React$Node => {
	const [interestOptions, setInterestOptions] = useState([]);
	const [user, setUser] = useState(EmptyUser);
	const { onUpload, onDelete } = useImageApi();

	const onImageDropLocal = useCallback((setFieldValue) => {
		return (acceptedFiles, rejectedFiles, options) =>
			onUpload([{
				file: acceptedFiles[0],
				owner: user?.id,
				mediaType: MediaType.IMAGE,
				override: true,
			}])
				.map(promise => promise.then(res => setFieldValue("photos", [transformPhoto(res.data, "profile photo")])))
	}, [user?.id, onUpload]);

	const onImageDeleteLocal = useCallback(key => {
		return onDelete(key)
			.then(() => setUser(state => {
				const stateClone = cloneDeep(state);
				stateClone.photos = [];
				return stateClone;
			}));
	}, [onDelete]);


	const onSubmit = useCallback(values => {
		return put(Backend.PROFILE_SETTINGS, values)
			.then(res => {
				if ( res.data?.jwt ) {
					AuthUtils.load(res.data.jwt)
				}
				notificationService.show({
					autoHide: 6000,
					level: "success",
					messages: [{code:'settings.profile.updated'}]
				});
			});
	}, []);

	useEffect(() => {

		get(Backend.COMMONS_TAGS_GROUPS, { groups: [
			TagGroup.ACTIVITIES,
			TagGroup.FOOD_AND_BEVERAGE,
			TagGroup.CUISINE_TYPE,
		]})
			.then(res => setInterestOptions(
				res.data
					.flatMap(group => group.tags.map(tag => ({ ...tag, tagGroup: { key: group.key, code: group.code } })))
					.map(tag => transformTag(tag))
			));

		get(Backend.PROFILE_SETTINGS, { fields: 'ids,profile_photo,first_name,last_name,display_name,email,bio,gender,age_group,interests,social_links' })
			.then(res => setUser(state => ({
					...state,
					...res.data,
					photos: res.data?.photos?.map(photo => transformPhoto(photo, "profile photo")),
					interests: res.data?.interests?.map(int => ({
						...int,
						code: int.title.toLowerCase().replace(/ /gi, "-")
					})),
					socialLinks: THIRD_PARTIES.map(thirdParty => {
						const socialLinkOld = res.data.socialLinks
							?.filter(social => social.thirdParty === thirdParty.code)
							?.[0];
						return socialLinkOld
							? socialLinkOld
							: { thirdParty: thirdParty.code, path: "" };
					})
				})
			));

	}, []);

	return (
		<ProfileEdit
			user={user}
			interestOptions={interestOptions}
			onSubmit={onSubmit}
			onImageDrop={onImageDropLocal}
			onImageDelete={onImageDeleteLocal}
		/>
	)
};

export default ProfileEditWrapper;