// @flow
import type { AccessTokenApiProps, RoleProps } from 'auth/types';
import { Roles } from 'auth/index';
import { jwtDecode } from 'jwt-decode';

const ROLE_PATH_MAP = {
	[Roles.ADMIN]: "/cms",
	[Roles.PARTNER]: "/cms",
	[Roles.SUPPLIER]: "/cms",
	[Roles.USER]: "/"
}

export const decodeJwt = (jwt: string): AccessTokenApiProps => jwt ? jwtDecode(jwt) : null;
export const isExpired = (exp: number): boolean => exp ? Date.now() > new Date(exp * 1000) : true;

export const getDominantRole = (authorities: Array<RoleProps>): RoleProps => {
	if ( authorities?.includes(Roles.ADMIN) ) return Roles.ADMIN;
	if ( authorities?.includes(Roles.PARTNER) ) return Roles.PARTNER;
	if ( authorities?.includes(Roles.SUPPLIER) ) return Roles.SUPPLIER;
	return Roles.USER;
};

export const getRolePath = (dominantRole: RoleProps): string => {
	return ROLE_PATH_MAP[dominantRole];
};