// @flow
export const TagGroup = {
	ACCOMMODATION: 'accommodation',
	CRUISE: 'cruise.type',
	EXCURSION: 'excursion.and.custom.experience',
	FOOD_AND_BEVERAGE: 'food.and.drinks',
	OTHER: 'other.services',
	SHOWS_AND_EVENTS: 'shows.and.events',
	TRANSFER: 'transfer',

	ACTIVITIES: 'activities',
  	INTERESTS: 'interests',
	FEATURES: 'features',
	FEELINGS: 'feelings',
	TARGET_GROUPS: 'target.groups',
	CUISINE_TYPE: 'cuisine.type',
	LOCATION: 'location',
};

export const TagGroupType = {
	NONE: 'NONE',
	PRIMARY: 'PRIMARY',
	SECONDARY: 'SECONDARY',
	FEATURES: 'FEATURES',
	AMENITIES: 'AMENITIES',
	ROOM_OPTIONS: 'ROOM_OPTIONS',
	ROOM_AMENITIES: 'ROOM_AMENITIES',
	SHIP_AMENITIES: 'SHIP_AMENITIES',
}

export default TagGroupType;
