// @flow
import { useState } from 'react';
import useI18n from 'hooks/i18n/useI18n';
import Gender from 'utils/variables/gender';
import AgeGroup from 'utils/variables/age-group';
import Button from 'components/general/button';
import TagButton from 'components/general/tag/tag-button';
import 'scss/components/auth/register/registration-age-gender.scss';

type Props = {
  onSubmit: Object => Promise<any>,
};

const RegistrationAgeGender = ({ onSubmit }: Props): React$Node => {
  const { t } = useI18n();
  const [ageGroup, setAgeGroup] = useState();
  const [gender, setGender] = useState();
  return (
    <div className="RegistrationAgeGender">
      <label className="RegistrationAgeGender__title">{t('registration.age_gender.age')}</label>
      <div className="RegistrationAgeGender__age">
        {
          Object.keys(AgeGroup).map(ageOption => (
            <TagButton
              key={ageOption}
              title={t(ageOption)}
              isActive={ageGroup === ageOption}
              onClick={() => setAgeGroup(ageOption)}
            />
          ))
        }
      </div>
      <label className="RegistrationAgeGender__title">{t('registration.age_gender.gender')}</label>
      <div className="RegistrationAgeGender__gender">
        {
          Object.keys(Gender).map(genderOption => (
            <TagButton
              key={genderOption}
              title={t(genderOption)}
              isActive={gender === genderOption}
              onClick={() => setGender(genderOption)}
            />
          ))
        }
      </div>
      <Button
        {...(!ageGroup || !gender ? {disabled: true} : {})}
        onClick={() => onSubmit({ ageGroup, gender })}
        style={{width:'100%'}}
        className="RegistrationAgeGender__submit"
      >
        { t('registration.age_gender.submit') }
      </Button>
    </div>
  );
}

export default RegistrationAgeGender;
