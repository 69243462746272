// @flow
import Emote from 'components/general/emote';
import "scss/components/general/tag/tag-button.scss";
import Icon from "components/general/icon";

type Props = {
	icon?: string,
	emote?: string,
	title: string,
	isActive: boolean,
	showEmote?: boolean,
	onClick: void => void,
	className?: string,
};

export const TagButton = ({ icon, emote, title, isActive, onClick, className = '' }: Props): React$Node => {
	const isActiveClass = isActive ? 'is-active' : '';
	const isEmoteClass = emote ? 'is-emote' : '';
	return (
		<button
			type="button"
			onClick={onClick}
			className={`TagButton ${isActiveClass} ${isEmoteClass} ${className}`}
		>
			{
				icon &&
				<Icon code={icon} />
			}
			{
				emote &&
				<Emote
					code={emote}
					title={emote}
					className="TagButton__emote"
				/>
			}
			{title}
		</button>
	);
}

export default TagButton;
