// @flow
import { HTML5_FMT, getPickerInstance, format } from 'utils/date';
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';

type ElyTimePickerProps = {
	name:string,
	label?:string,
	placeholder?:string,
	value?:Object,
	dateFormat?:string,
	disabled?:boolean,
	clearable?: boolean,
	autoOk?:boolean,
	disablePast?:boolean,
	InputProps?: Object,
	variant?: 'standard' | 'outlined' | 'filled',
	onChange?: string => void,
	onBlur?: SyntheticEvent<HTMLInputElement> => void,
	onClose?: SyntheticEvent<HTMLInputElement> => void
};

const ElyTimePicker = ({
	name,
	label,
	value,
	dateFormat,
	disabled,
	clearable,
	autoOk,
	disablePast,
	InputProps,
	variant,
	onChange,
	onBlur,
	onClose
}: ElyTimePickerProps): React$Node => {
	const valueFormatted = value ? getPickerInstance(value, HTML5_FMT.TIME) : null;
	const onChangeLocal = time => onChange?.(format(time, HTML5_FMT.TIME));
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<TimePicker
				id={name}
				name={name}
				label={label}
				value={valueFormatted}
				ampm={false}
				onChange={onChangeLocal}
				disabled={disabled}
				disablePast={disablePast}
				InputProps={InputProps}
				renderInput={(props) => (
					<TextField
						{...props}
						variant={variant}
						helperText=""
					/>
				)}

			/>
		</LocalizationProvider>
	);
}

const emptyObject: Object = {};
ElyTimePicker.defaultProps = {
	variant: 'standard',
	InputProps: emptyObject,
	clearable: false,
	autoOk: true,
	disablePast: false,
}

export default ElyTimePicker;