// @flow
import { ThirdParty } from 'utils/variables/third-party';
import type { ThirdPartyProps } from 'types/general/commons';
import type { PhotoProps } from 'types/general/photo-props';
import { type LocationProps, LocationEmpty } from 'types/general/location-props';
import type {FeelProps} from "types/feel-props";

export type InterestProps = {
	id: string,
	key: string,
	code: string,
	title: string,
	updatedAt?: string
}

export type SocialLinksProps = {
	thirdParty: ThirdPartyProps,
	path: string
}

export type IsFriendProps = {
	token?: string,
	active?: boolean,
	isOutbound?: boolean,
}

export type UserProps = {
	path?: string,
	link?: string,
	id: string,
	key: string,
	name: string,
	email?: string,
	firstName?: string,
	lastName?: string,
	displayName?: string,
	feel?: FeelProps,
	bio?: string,
	phone?: string,
	location?: LocationProps,
	photos?: Array<PhotoProps>,
	interests?: Array<InterestProps>,
	socialLinks?: Array<SocialLinksProps>,
	isMe?: boolean,
	isFriend?: boolean,
}

export const InterestEmpty: InterestProps = {
	id: "",
	key: "",
	code: "",
	title: "",
	updatedAt: ""
};

export const SocialLinksEmpty: Array<SocialLinksProps> = [
	{ thirdParty: ThirdParty.TWITTER.code, path: "" },
	{ thirdParty: ThirdParty.FACEBOOK.code, path: "" },
	{ thirdParty: ThirdParty.INSTAGRAM.code, path: "" }
];

export const EmptyUser: UserProps = {
	id:"",
	key:"",
	name: "",
	firstName: "",
	lastName: "",
	displayName: "",
	bio: "",
	email: "",
	phone: "",
	gender: null,
	ageGroup: null,
	location: LocationEmpty,
	interests: [],
	socialLinks: SocialLinksEmpty
};