const AgeGroup = {
  AG_UNDER_18: 'AG_UNDER_18',
  AG_18_24: 'AG_18_24',
  AG_25_34: 'AG_25_34',
  AG_35_44: 'AG_35_44',
  AG_45_54: 'AG_45_54',
  AG_55_64: 'AG_55_64',
  AG_65_OR_OVER: 'AG_65_OR_OVER',
  AG_PREFER_NOT_TO_SAY: 'AG_PREFER_NOT_TO_SAY',
};

export const AgeGroupOptions = [
    AgeGroup.AG_UNDER_18,
    AgeGroup.AG_18_24,
    AgeGroup.AG_25_34,
    AgeGroup.AG_35_44,
    AgeGroup.AG_45_54,
    AgeGroup.AG_55_64,
    AgeGroup.AG_65_OR_OVER,
    AgeGroup.AG_PREFER_NOT_TO_SAY,
]

export default AgeGroup;
