// @flow
import Button from 'components/general/button';
import userPlaceholder from 'img/user-placeholder.png';
import 'scss/components/auth/register/registration-success.scss';

type Props = {
	image: string,
	points: number,
	onSubmit: void => void,
	t: string => string,
};

const RegistrationSuccess = ({ image, points = 0, onSubmit, t }: Props): React$Node => (
	<div className="RegistrationSuccess">
		<div className="RegistrationSuccess__image">
			<img
				alt="profile"
				src={image ?? userPlaceholder}
			/>
			<span className="points">{`+${points}`}</span>
		</div>
		<span className="RegistrationSuccess__description1">{t('registration.success.description1', { points })}</span>
		<span className="RegistrationSuccess__description2">{t('registration.success.description2', { points })}</span>
		<Button onClick={onSubmit} className="RegistrationSuccess__submit">{t('registration.success.submit')}</Button>
	</div>
);

export default RegistrationSuccess;
