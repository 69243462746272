const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  BEYOND_BINARY: 'BEYOND_BINARY',
  PREFER_NOT_TO_SAY: 'PREFER_NOT_TO_SAY',
};

export const GenderOptions = [
    Gender.MALE,
    Gender.FEMALE,
    Gender.BEYOND_BINARY,
    Gender.PREFER_NOT_TO_SAY
];

export default Gender;
